@import 'globals';

.onboarding {
    .welcome {
        height: 100vh;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        align-items: center;
        justify-content: center;

        .loader {
            border-radius: 10px;
            height: 6px;
            background-color: white;

            .MuiLinearProgress-bar {
                background: map-get($map: $colors, $key: 'primary');
            }
        }

        img {
            width: 400px;
        }
    }

    .layout {
        height: 100vh;

        .content {
            display: flex;
            align-items: center;
            justify-content: center;

            p {
                color: #000;
                align-self: baseline;
                width: 70%;
                max-width: 500px;
                display: block;
                margin: 0 auto;
                font-size: 1.5rem;

                &.terms {
                    margin: 0;
                    display: inline;
                    font-size: 13px;

                    // a {
                    //     color: map-get($map: $colors, $key: "primary");
                    // }
                }
            }

            .widget-designer {
                align-items: center;
                justify-content: center;
                display: flex;
                flex-direction: column;
                text-align: center;
            }

            .box {
                width: 70%;
                max-width: 500px;
                background: #FFF;
                box-shadow: 0 4px 60px rgba(0, 0, 0, 0.05);
                border-radius: 16px;
                padding-top: 20px;

                button {
                    width: 95%;
                    padding: 15px;
                    margin: 0 auto;
                    display: block;
                    box-shadow: none;
                    margin-bottom: 20px;
                    border-radius: 6px;
                    font-family: 'Circular Std', sans-serif;
                }

                label {
                    width: 95%;
                    margin: 0 auto;
                    display: block;
                    font-weight: normal;
                }

                .StripeElement {
                    padding: 10px 0 10px 20px;
                }

                .MuiFormControl-root {
                    width: 95%;
                    margin: 10px auto;
                    display: block;
                    border-radius: 6px;

                    &.disabled {
                        background-color: #EEEEEE;
                        border: none;

                        fieldset {
                            border-radius: 6px;
                            border: none
                        }
                    }

                    .MuiInputBase-root {
                        width: 100%;
                    }
                }

                .MuiOutlinedInput-root {
                    width: 95%;
                    margin: 10px auto;
                    display: block;
                }
            }
        }

        .logo {
            width: 50%;
            min-width: 300px;
        }

        p {
            color: #FFF;
            margin-bottom: 0;

            &.title {
                font-size: 3.5rem;
            }

            &.subtitle {
                font-size: 1.5rem;
                font-weight: normal;
                white-space: normal !important;
            }

            &.regular {
                font-size: 1rem;
                font-weight: normal;
            }
        }

        .background-image {
            overflow: hidden;
            position: absolute;
            width: 50%;
            z-index: -1;
            height: 100vh;
        }

        .MuiStepLabel-label {
            color: #FFF;
        }

        .MuiStepper-root {
            margin-left: -5%;
        }

        .MuiStepLabel-labelContainer {
            width: fit-content;
            text-align: center;

            .MuiStepLabel-label {
                width: fit-content;
            }
        }

        .MuiCheckbox-root .MuiSvgIcon-root {
            // color: map-get($colors, "primary");
        }

        .MuiStepLabel-iconContainer.Mui-disabled {
            svg.MuiSvgIcon-root {
                color: #FFF;
            }
        }

        svg.MuiSvgIcon-root {

            // color: #FFF;

            .MuiStepIcon-text {
                fill: #000;
            }

            &.Mui-active {
                // color: green($color: #000000);

                .MuiStepIcon-text {
                    fill: #fff;
                }
            }
        }
    }
}

.StripeElement {
    width: 90%;
    padding-bottom: 20px;
    color: #32325d;
    background-color: white;
    // border: 1px solid transparent;
    border-radius: 4px;

    // box-shadow: 0 1px 3px 0 #e6ebf1;
    -webkit-transition: box-shadow 150ms ease;
    transition: box-shadow 150ms ease;
}

.StripeElement--invalid {
    border-color: #fa755a;
}

.StripeElement--webkit-autofill {
    background-color: #fefde5 !important;
}