@import "globals";

.box-with-icon {
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid map-get($map: $colors, $key: "grey");
    border-radius: $borderRadius;
    width: 88%;
    margin: 10px auto;
    cursor: pointer;

    p {
        color: map-get($map: $colors, $key: "grey")!important;
        font-weight: normal;
        font-size: 1rem!important;
        align-self: auto!important;
        margin-left: 20px!important;
    }

    img {
        filter: invert(85%) sepia(0%) saturate(0%) hue-rotate(248deg) brightness(102%) contrast(82%);
        width: 25px!important;
        min-width: 0px!important;
        margin-left: 10px;
    }

    &.active {
        img {
            filter: invert(32%) sepia(81%) saturate(2204%) hue-rotate(194deg) brightness(102%) contrast(107%);
        }
        border-color: map-get($map: $colors, $key: "secondary");
    }
}