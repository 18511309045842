@import "globals";

.dashboard-layout {
  display: flex;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-width: 100vw;
  max-width: 100vw;
  min-height: 100vh;
  max-height: 100vh;
  overflow: hidden;
  font-family: "Circular Std", "sans-serif";
  font-weight: normal;
}

.nav-bar {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  min-width: 10rem;
  max-width: 16vw;
  min-height: 100%;
}

.nav-bar-top {
  display: flex;
  align-items: center;
  div {
    display: flex;
    padding: 1.5rem 1.25rem;
    img {
      margin: auto;
      width: auto;
      height: 39px;
    }
  }
  min-height: 4rem;
  min-width: 100%;
  @media screen {
  }
}

.nav-bar-content {
  background-position-x: left;
  background-position-y: bottom;
  background-repeat: no-repeat;
  background-size: 90%;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  min-height: 18.55rem;
  height: calc(100vh - 4rem);
  overflow-y: auto;
  overflow-x: hidden;
}

.main-content {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 84%;
  min-width: 40%;
  max-width: 84vw;
  min-height: 100%;
  overflow: hidden;
}

// .main-content::before {
//   background-position-x: right;
//   background-position-y: bottom;
//   background-repeat: no-repeat;
//   background-size: 90%;
//   position: absolute;
//   min-height: inherit;
//   min-width: inherit;
//   max-width: inherit;
//   right: 0;
//   bottom: 0;
// }

.primary-top-bar {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 1.25rem;
  min-height: 5rem;

  color: antiquewhite;
}

.primary-top-bar-content {
  display: flex;
  gap: 10px;
  align-items: center;
  font-size: smaller;
  color: white;
}

.secondary-top-bar {
  .page-title {
    font-size: 2.8rem;
    color: white;
    font-family: NunitoSans-Regular;
  }
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: small;
  min-height: 2.5rem;
  padding: 0 1.25rem;
  margin-bottom: 0;

  color: white;
}

.page-content {
  font-family: NunitoSans-SemiBold;
  .page-content-top-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-width: -webkit-fill-available;
    padding-bottom: 0.5rem;
    .content-title {
      font-size: 2.8rem;
    }
  }
  background-color: map-get($map: $colors, $key: "white");
  border-radius: 10px;
  margin: 1.25rem;
  padding: 0.5rem 1.25rem 1.25rem;
  height: calc(100vh - 6.5rem);
  min-height: 12rem;
  overflow: auto;
}

.buton {
  display: flex !important;
  gap: 0.25em !important;
  color: white !important;
  background-color: map-get($map: $colors, $key: "light-green") !important;
  font-size: small !important;
  height: 30px !important;
  padding: 2px !important;

  &.Mui-disabled {
    background-color: grey!important;
  }
}

h6 {
  font-family: NunitoSans-SemiBold !important;
  font-weight: normal !important;
}

p {
  font-family: NunitoSans-SemiBold !important;
  font-weight: normal !important;
}

p.bold {
  font-family: NunitoSans-Bold !important;
  font-weight: normal !important;
}

strong {
  font-family: NunitoSans-Bold !important;
  font-weight: normal !important;
}

p.sub-text {
  font-family: NunitoSans-Regular !important;
  font-weight: normal !important;
}
