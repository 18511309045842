@import "globals";

.attribute-container {
    .attribute {

        display: flex;
        align-items: center;
        justify-content: center;
        width: 90%;
        margin: 0 auto;

        p {
            color: map-get($map: $colors, $key: "grey")!important;
            margin-left: 10px!important;
            font-size: 1rem!important;
            font-weight: 500;
            width: 100%!important;
        }

        div {
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background-color: map-get($map: $colors, $key: "grey");
        }
        
        margin-bottom: 15px;
    }

    &.active  {
        .attribute {
            div {
                background-color: map-get($map: $colors, $key: "secondary");
            }

            p {
                color: #545555!important;
            }
        }
    }
}