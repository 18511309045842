@import url("http://fonts.cdnfonts.com/css/circular-std");

$colors: (
  "primary": #24aa3c,
  "secondary": #0085ff,
  "grey": #959595,
  "light-green": rgb(76, 155, 90),
  "white": rgb(255, 255, 255),
  "green": rgb(24, 122, 49),
);

$borderRadius: 6px;

body {
  font-family: "Circular Std", sans-serif;
  font-weight: bold;
}

@font-face {
  font-family: "NunitoSans-Regular";
  src: url(./Fonts/NunitoSans/NunitoSans-Regular.ttf);
}

@font-face {
  font-family: "NunitoSans-Bold";
  src: url(./Fonts/NunitoSans/NunitoSans-Bold.ttf);
}

@font-face {
  font-family: "NunitoSans-SemiBold";
  src: url(./Fonts/NunitoSans/NunitoSans-SemiBold.ttf);
}
